import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../general/belowFoldCards"
import Money from "../../../../assets/img/pound.inline.svg"
import Budget from "../../../../assets/img/pay-icon.inline.svg"
import Account from "../../../../assets/img/userIcon.inline.svg"
import PhoneBlur from "../../../../assets/img/circle.inline.svg"
import { scrollToElement } from "../../../utility/utils"
import AccountIcon from "../../../../assets/img/ukResident.inline.svg"
import CTAUk from "../general/ctaUk"
import HomeHeaderFoldAnimate from "./sections/homeHeaderFoldAnimate"
import AnimationTextRightAnimate from "./sections/animationTextRightAnimate"
import AnimationTextLeftAnimate from "./sections/animationTextLeftAnimate"
import Pricing from "./sections/pricing"


const entryContent = {
    title: "We're the money app for Africans!",
    subtitle: (
        <span>
            Make transfers within the UK and send <br />
            money from the UK to Nigeria easily <br />
            with the Kuda app.
        </span>
    ),
}

const kudaTopFeatures = [
    {
        icon: <Money />,
        text: "Free transfers within the UK",
    },
    {
        icon: <Budget />,
        text: "Send money to Nigeria at low rates.",
    },
    {
        icon: <Account />,
        text: "Open an account in minutes.",
    },
]


const HomeUk = () => {
    const yourMoney = {
        title: "All UK residents are welcome.",
        subtitle:
            "Whether you're new to the UK or it has always been home, you can open a Kuda account in minutes with your valid ID and a proof of UK address",
        buttonName: "Open An Account Now",
        buttonUrl: "/joinKuda/",
        animation: "money-manage-uk",
    }

    const phoneDebit = {
        title: (
            <span>
                Send and receive <br />
                money locally.
            </span>
        ),
        subtitle:
            "Add money to your brand-new Kuda account and send it to any UK bank account or share your Kuda account details to receive transfers.",
        buttonName: "Join Kuda",
        buttonUrl: "/joinKuda/",
        animation: "send-uk",
    }

    const blockCard = {
        title: "Move money across borders.",
        subtitle:
            "Keep your Nigerian connections going with fast international transfers to friends and family at rates you can afford. More African transfer destinations are on the way.",
        buttonName: "Send Money With Kuda",
        buttonUrl: "/en-gb/payments/",
        animation: "transfer-uk",
    }


    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <HomeHeaderFoldAnimate
                title={entryContent.title}
                subtitle={entryContent.subtitle}
            />
            <BelowFoldCards
                topFeatures={kudaTopFeatures}
            />
            <AnimationTextRightAnimate
                title={yourMoney.title}
                subtitle={yourMoney.subtitle}
                fallBack={<PhoneBlur className="blur" />}
                animationUrl={yourMoney.animation}
                buttonName={yourMoney.buttonName}
                buttonUrl={yourMoney.buttonUrl}
                image={<AccountIcon />}
            />
            <AnimationTextLeftAnimate
                title={phoneDebit.title}
                subtitle={phoneDebit.subtitle}
                buttonName={phoneDebit.buttonName}
                buttonUrl={phoneDebit.buttonUrl}
                fallBack={<PhoneBlur className="blur" />}
                animationUrl={phoneDebit.animation}
                isWebButton={true}
            />


            <AnimationTextRightAnimate
                title={blockCard.title}
                subtitle={blockCard.subtitle}
                buttonName={blockCard.buttonName}
                buttonUrl={blockCard.buttonUrl}
                fallBack={<PhoneBlur className="blur" />}
                animationUrl={blockCard.animation}
            />

            <Pricing /> 
            <CTAUk />
        </Fragment>
    )
}

export default HomeUk
