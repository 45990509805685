import React from "react";
import AtlanticLogo from "../../../../../assets/img/atlantic-money.inline.svg"
import WiseLogo from "../../../../../assets/img/wise.inline.svg";
import FlutterwaveLogo from "../../../../../assets/img/flutterwave.inline.svg"
import KudaLogo from "../../../../../assets/img/kuda-pricing.inline.svg";
import WorldRemitLogo from "../../../../../assets/img/world-remit.inline.svg";
import SendWaveLogo from "../../../../../assets/img/sendwave.inline.svg";
import PaySendLogo from "../../../../../assets/img/paysend.inline.svg";
import RiaLogo from "../../../../../assets/img/ria-logo.inline.svg";
import MukuruLogo from "../../../../../assets/img/mukuru.inline.svg";
import RemitlyLogo from "../../../../../assets/img/remitly.inline.svg";


const Pricing = () => {

    const feature = [{
        title: "Mid-Market Rate",
        money100: "$115.53",
        money500: "$577.64",
        money1000: "$1,155.28",
    },
    {
        title: <AtlanticLogo />,
        money100: "$112.15",
        money500: "$574.60",
        money1000: "$1,152.66",
        support: "Doesn’t support 🇳🇬"
    },
    {
        title: <WiseLogo />,
        money100: "$114.47",
        money500: "$573.59",
        money1000: "$1,147.49",
        support: "Doesn’t support 🇳🇬"
    }, {
        title: <FlutterwaveLogo />,
        money100: "$114.53",
        money500: "$572.69",
        money1000: "$1,145.39",
    }, {
        title: <KudaLogo />,
        money100: "$111.50",
        money500: "$571.30",
        money1000: "$1,146.06",
        color: "kuda"
    },
    {
        title: <WorldRemitLogo />,
        money100: "$114.15",
        money500: "$570.75",
        money1000: "$1,141.50",
    }, {
        title: <SendWaveLogo />,
        money100: "$114.00",
        money500: "$570.00",
        money1000: "N/A",
    }, {
        title: <PaySendLogo />,
        money100: "$112.60",
        money500: "$567.55",
        money1000: "$1,136.24",
    }, {
        title: <RiaLogo />,
        money100: "$110.54",
        money500: "$565.91",
        money1000: "N/A",
    }, {
        title: <RemitlyLogo />,
        money100: "$113.00",
        money500: "$565.00",
        money1000: "$1,130.00",
    }, {
        title: <MukuruLogo />,
        money100: "$111.00",
        money500: "$556.00",
        money1000: "$1,111.00",
    }]

    return (
        <div className="kuda-section kuda-standard--section kuda-choose--freedom" >
            <div className="kuda-section--inner">
                <div className="kuda-section--100">
                    <h1 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
                        Competitive Pricing
                    </h1>
                    <div className="flex justify-center kuda-plan--wrap">
                        <div className="plan-full">
                            <div className="plan-details pricing-details">

                                <div className="plan-features">
                                    <div className="plan-heading flex show-mobile" style={{paddingTop: "20px"}}>
                                        <div className="plan-single">
                                            <div className="plan-single">
                                                <h2 className="card-main--heading color-primary text-xlbold"></h2>
                                            </div>
                                        </div>
                                        <div className="plan-single">
                                            <h2 className="card-main--heading color-primary text-xlbold">£100</h2>
                                        </div>
                                        <div className="plan-single">
                                            <h2 className="card-main--heading color-primary text-xlbold text-center">£500</h2>
                                        </div>
                                        <div className="plan-single">
                                            <h2 className="card-main--heading color-primary text-xlbold text-right">£1000</h2>
                                        </div>
                                    </div>

                                    {feature.map((item, i) =>
                                        <div className={`pricing-single--wrap show-mobile ${item.color === "kuda" ? "kuda-color" : ""}`} key={i}>
                                            <div style={{width: "100%"}}>
                                                <div className="flex justify-between">
                                                    <p className="feature-summary color-black text-bold flex align-flex-start">
                                                        <span className="feature-text height-full">{item.title}</span>
                                                    </p>
                                                    <p className="feature-summary color-black text-bold flex align-flex-start">
                                                        <span className="feature-text pricing-text height-full">{item.support}</span>
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <div className="plan-single pricing-single">
                                                        <span className={`feature-text pricing-text height-full color-black ${item.color === "kuda" ? "kuda-text-color" : ""}`}>{item.money100}</span>
                                                    </div>
                                                    <div className="plan-single pricing-single">
                                                        <span className={`feature-text pricing-text height-full color-black justify-center ${item.color === "kuda" ? "kuda-text-color" : ""}`}>{item.money500}</span>
                                                    </div>
                                                    <div className="plan-single pricing-single">
                                                        <span className={`feature-text pricing-text height-full color-black justify-content-end ${item.color === "kuda" ? "kuda-text-color" : ""}`}>{item.money1000}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }

                                    <div className="kuda-table bottom--spacing desktop-mobile">
                                        <table border="1">
                                            <thead>
                                                <tr className="pricing-head">
                                                    <th/>
                                                            <th> <h2 className="card-main--heading color-primary text-xlbold">£100</h2></th>
                                                            <th> <h2 className="card-main--heading color-primary text-xlbold">£500</h2></th>
                                                            <th> <h2 className="card-main--heading color-primary text-xlbold">£1000</h2></th>
                                                            <th/>
                                                        </tr>
                                            </thead>
                                            <tbody>
                                                {feature.map((item, i) =>
                                                    <tr className={`${item.color === "kuda" ? "kuda-color" : ""}`}>
                                                        <td className="text-bold padd">{item.title}</td>

                                                        <td className={`${item.color === "kuda" ? "kuda-text-color" : "pricing-text"}`} >{item.money100}</td>
                                                        <td className={`${item.color === "kuda" ? "kuda-text-color" : "pricing-text"}`}>{item.money500}</td>
                                                        <td className={`${item.color === "kuda" ? "kuda-text-color" : "pricing-text"}`}>{item.money1000}</td>
                                                        <td>{item.support}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing;
