import React, { useEffect, useState } from "react";
import Lottie from 'react-lottie';
import Animation from '../../../../../assets/animations/home-ring/data.json'
import EntryBadges from "../../general/badges.js";
import EntryBadgesV2 from "../../general/badgesV2";
import License from "../../general/licenses"


const HomeHeaderFoldAnimate = (props) => {
    const [state, setState] = useState({
        isStopped: false,
        phoneStopped: true,
        animation2: "",
        animation: "",
    });
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Animation,
        rendererSettings: {
            preserveAspectRatio: 'xMaxYMax meet',
            progressiveLoad: false,
            className: 'kuda-ring--illustration'
        }
    };

    const phoneOptions = {
        loop: false,
        autoplay: false,
        animationData: state.animation2,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        }
    };

    useEffect(() => {
        import(`../../../../../assets/animations/uk-phone/data.json`).then(background => {
            setState(prevState => ({
                ...prevState,
                animation2: background,
                phoneStopped: false
            }))

        })
    }, [])

    return (

        <div className="kuda-entry--fold home-fold" >
            <div className="kuda-entry--inner flex justify-between align-center" >
                <div className="kuda-section--42">
                    <div className="kuda-main--wrap">
                        <h1 className="text-xl kuda-main--heading text-xlbold color-primary title-bottom--spacing">
                            {props.title}
                        </h1>
                        <p className="card-main--heading text-semi-bold color-black title-bottom--spacing">
                            {props.subtitle}
                        </p>
                        <EntryBadgesV2 className="mobile-only bottom--spacing" />
                        <License className="mobile-only bottom--spacing" />
                        <EntryBadges className={"desktop-only"}/>
                    </div>
                </div>
                <div className="kuda-section--58">
                    <div className={`kuda-illustration--center hasAnimation`}>

                        <Lottie options={defaultOptions}
                            height={`100%`}
                            width={`100%`}
                            isStopped={state.isStopped}
                        />



                        <div className="kuda-phone--illustration">
                            <Lottie options={phoneOptions}
                                height={`100%`}
                                width={`100%`}
                                isStopped={state.phoneStopped}
                            />

                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default HomeHeaderFoldAnimate;
