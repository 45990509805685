import React from "react"
import HomeUk from "../../components/body/pages/en-gb"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-gb/"}
      title="Kuda | The Money App for Africans | Open an Account in Minutes"
      description="Kuda, the money app for Africans. Enjoy zero maintenance fees, free transfers, low-cost transfers to Nigeria & more. Join Kuda today!"
    />
    <HomeUk />
  </Layout>
)

export default IndexPage
